import React, { Component } from "react";
import { connect } from "react-redux";
import { DgPicture } from "dg-utils";
import { Link } from "react-router-dom";
import intl from 'react-intl-universal';

// Assets
import moneyManagment from "../../../../assets/images/money-managment-alt.png";

//Component
import LeftRight from "../../../blocks/leftRight/leftRight";

//Actions
import { navigateTo } from "../../../../actions/utility.action";

class HomeFree extends Component {
    render() {
        return (
            <LeftRight
                left={
                    <div className="wrapper">
                        <DgPicture>
                            <img
                                src={moneyManagment}
                                alt=""
                                loading="lazy"
                            />
                        </DgPicture>
                    </div>
                }
                right={
                    <div className="wrapper">
                        <h2>{ intl.get('page.Home.Free.h') }</h2>
                        <p>
	                        { intl.getHTML('page.Home.Free.p1') }
                        </p>
                        <p>
	                        { intl.get('page.Home.Free.p2') }
	                        {" "}
                            <Link onClick={() => navigateTo()} to={`/terms-and-conditions?new=yes`} className="embedded-link inverted">
	                            { intl.get('page.Home.Free.feesLink') }
                            </Link>{" "}
	                        {" "}
	                        { intl.get('page.Home.Free.p3') }
                        </p>
                        <button className="btn-primary inverted">
                            <Link onClick={() => navigateTo()} to="/spend">{ intl.get('page.Home.Free.cta') }</Link>
                        </button>
                    </div>
                }
                config={`green right`}
            />
        );
    }
}

export default connect(null, { navigateTo })(HomeFree);
